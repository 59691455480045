import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {
    SET_ORGANIZATIONS,
    SET_SERVICES,
    SET_PRODUCTS,
    SET_SCHEDULES,
    SET_AVAILABILITIES,
    SET_ORGANIZATIONS_LOADING,
    SET_SERVICES_LOADING,
    SET_PRODUCTS_LOADING,
    SET_SCHEDULES_LOADING,
    SET_AVAILABILITIES_LOADING,
    SET_ORG_DETAILS,
    SET_ORG_DETAILS_LOADING,
    SET_BUSINESS_CATEGORIES,
    SET_BUSINESS_CATEGORIES_LOADING,
    SET_SERVICE_CATEGORIES,
    SET_SERVICE_CATEGORIES_LOADING,
    SET_PRODUCT_CATEGORIES,
    SET_PRODUCT_CATEGORIES_LOADING,
    SET_PRODUCT_DETAILS,
    SET_PRODUCT_DETAILS_LOADING,
    SET_SERVICE_DETAILS_LOADING,
    SET_SERVICE_DETAILS,
    SET_SCHEDULE_DETAILS,
    SET_SCHEDULE_DETAILS_LOADING,
    SET_PROVIDER_LIST,
    SET_PROVIDER_LIST_LOADING,
    SET_BOOKING_DETAILS,
    SET_BOOKING_DETAILS_LOADING,
    SET_CUSTOMER_SERVICE_STATUS,
    SET_SURVEY_DATA_LOADING,
    SET_SURVEY_DATA,
    SET_SURVEY_ANSWER,
    SET_FEEDBACK_ANSWER,
    SET_BOOKING_LIST,
    SET_BOOKING_LIST_LAST_PAGE,
    SET_BOOKING_LIST_TOTAL_COUNT,
    SET_BOOKING_LIST_LOADING,
    SET_TWILIO_ACCESS_DATA,
    SET_TWILIO_ACCESS_DATA_LOADING,
    SET_RATING_REVIEWS_LOADING,
    SET_RATING_REVIEWS,
    SET_SUBSCRIPTION_PLANS,
    SET_SUBSCRIPTION_PLANS_LOADING,
} from '../actions/apiActions';

const initState = {
    organizationList: [],
    organizationListLoading: false,
    businessCategories: [],
    businessCategoriesLoading: false,
    serviceList: [],
    serviceListLoading: false,
    serviceCategories: [],
    serviceCategoriesLoading: false,
    productList: [],
    productListLoading: false,
    productCategories: [],
    productCategoriesLoading: false,
    scheduleList: [],
    scheduleListLoading: false,
    availabilities: [],
    availabilitiesLoading: [],
    orgDetails: {},
    orgDetailsLoading: false,
    productDetails: {},
    productDetailsLoading: false,
    serviceDetails: {},
    serviceDetailsLoading: false,
    scheduleDetails: {},
    scheduleDetailsLoading: false,
    providerList: [],
    providerListLoading: false,
    bookingDetails: {},
    bookingDetailsLoading: false,
    customerServiceStatus: null,
    surveyData: null,
    surveyDataLoading: false,
    surveyAnswer: null,
    feedbackAnswer: null,
    bookingList: [],
    bookingListTotalCount: 0,
    bookingListLastLoadedPage: -1,
    bookingListLoading: false,
    twilioAccessData: null,
    twilioAccessDataLoading: null,
    ratingReviewsData: {},
    ratingReviewsDataLoading: {},
    subscriptionPlans: [],
    subscriptionPlansLoading: false,
};
const persistConfig = {
    key: 'api',
    storage: storageSession,
    stateReconciler: autoMergeLevel2,
    blacklist: [],
};
const reducer = (state = initState, action) => {
    switch (action.type) {
        case SET_ORGANIZATIONS:
            return {
                ...state,
                organizationList: action.payload,
            };
        case SET_ORGANIZATIONS_LOADING:
            return {
                ...state,
                organizationListLoading: action.payload,
            }
        case SET_BUSINESS_CATEGORIES:
            return {
                ...state,
                businessCategories: action.payload,
            };
        case SET_BUSINESS_CATEGORIES_LOADING:
            return {
                ...state,
                businessCategoriesLoading: action.payload,
            }
        case SET_ORG_DETAILS:
            return {
                ...state,
                orgDetails: action.payload,
            };
        case SET_ORG_DETAILS_LOADING:
            return {
                ...state,
                orgDetailsLoading: action.payload,
            }
        case SET_SERVICES:
            return {
                ...state,
                serviceList: action.payload,
            };
        case SET_SERVICES_LOADING:
            return {
                ...state,
                serviceListLoading: action.payload,
            }
        case SET_PRODUCTS:
            return {
                ...state,
                productList: action.payload,
            };
        case SET_PRODUCTS_LOADING:
            return {
                ...state,
                productListLoading: action.payload,
            }
        case SET_SCHEDULES:
            return {
                ...state,
                scheduleList: action.payload,
            };
        case SET_SCHEDULES_LOADING:
            return {
                ...state,
                scheduleListLoading: action.payload,
            }
        case SET_AVAILABILITIES:
            return {
                ...state,
                availabilities: { ...state.availabilities, ...action.payload },
            };
        case SET_AVAILABILITIES_LOADING:
            return {
                ...state,
                availabilitiesLoading: { ...state.availabilitiesLoading, ...action.payload },
            }
        case SET_SERVICE_CATEGORIES:
            return {
                ...state,
                serviceCategories: action.payload,
            };
        case SET_SERVICE_CATEGORIES_LOADING:
            return {
                ...state,
                serviceCategoriesLoading: action.payload,
            }
        case SET_PRODUCT_CATEGORIES:
            return {
                ...state,
                productCategories: action.payload,
            };
        case SET_PRODUCT_CATEGORIES_LOADING:
            return {
                ...state,
                productCategoriesLoading: action.payload,
            }
        case SET_PRODUCT_DETAILS:
            return {
                ...state,
                productDetails: action.payload,
            };
        case SET_PRODUCT_DETAILS_LOADING:
            return {
                ...state,
                productDetailsLoading: action.payload,
            }
        case SET_SERVICE_DETAILS:
            return {
                ...state,
                serviceDetails: action.payload,
            };
        case SET_SERVICE_DETAILS_LOADING:
            return {
                ...state,
                serviceDetailsLoading: action.payload,
            }
        case SET_SCHEDULE_DETAILS:
            return {
                ...state,
                scheduleDetails: action.payload,
            };
        case SET_SCHEDULE_DETAILS_LOADING:
            return {
                ...state,
                scheduleDetailsLoading: action.payload,
            }
        case SET_PROVIDER_LIST:
            return {
                ...state,
                providerList: action.payload,
            };
        case SET_PROVIDER_LIST_LOADING:
            return {
                ...state,
                providerListLoading: action.payload,
            }
        case SET_BOOKING_DETAILS:
            return {
                ...state,
                bookingDetails: action.payload,
            };
        case SET_BOOKING_DETAILS_LOADING:
            return {
                ...state,
                bookingDetailsLoading: action.payload,
            }
        case SET_CUSTOMER_SERVICE_STATUS:
            return {
                ...state,
                customerServiceStatus: action.payload,
            }
        case SET_SURVEY_DATA:
            return {
                ...state,
                surveyData: action.payload,
            }
        case SET_SURVEY_DATA_LOADING:
            return {
                ...state,
                surveyDataLoading: action.payload,
            }
        case SET_SURVEY_ANSWER:
            return {
                ...state,
                surveyAnswer: action.payload,
            }
        case SET_FEEDBACK_ANSWER:
            return {
                ...state,
                feedbackAnswer: action.payload,
            }
        case SET_BOOKING_LIST:
            return {
                ...state,
                bookingList: action.payload,
            }
        case SET_BOOKING_LIST_LAST_PAGE:
            return {
                ...state,
                bookingListLastLoadedPage: action.payload,
            }
        case SET_BOOKING_LIST_TOTAL_COUNT:
            return {
                ...state,
                bookingListTotalCount: action.payload,
            }
        case SET_BOOKING_LIST_LOADING:
            return {
                ...state,
                bookingListLoading: action.payload,
            }
        case SET_TWILIO_ACCESS_DATA:
            return {
                ...state,
                twilioAccessData: action.payload,
            }
        case SET_TWILIO_ACCESS_DATA_LOADING:
            return {
                ...state,
                twilioAccessDataLoading: action.payload,
            }
        case SET_RATING_REVIEWS:
            let id = action.payload.serviceId || action.payload.providerId;
            return {
                ...state,
                ratingReviewsData: { ...state.ratingReviewsData, [id]: action.payload },
            }
        case SET_RATING_REVIEWS_LOADING:
            let loadingId = action.payload.id;
            return {
                ...state,
                ratingReviewsDataLoading: { ...state.ratingReviewsDataLoading, [loadingId]: action.payload.value },
            }
        case SET_SUBSCRIPTION_PLANS:
            return {
                ...state,
                subscriptionPlans: action.payload,
            }
        case SET_SUBSCRIPTION_PLANS_LOADING:
            return {
                ...state,
                subscriptionPlansLoading: action.payload,
            }

        default:
            return state;
    }
};

export default persistReducer(persistConfig, reducer);
