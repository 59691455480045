import { useState } from "react";

import freePlanImage from '../../assets/images/landing/baby.png';
import growthPlanImage from '../../assets/images/landing/boy.png';
import proPlanImage from '../../assets/images/landing/pro-2.png';
import enterprisePlanImage from '../../assets/images/landing/enter.png';

import ToggleTabSwitch from '../../components/CustomSwitch/ToggleTabSwitch';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { Box, Button, Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Config } from "../../config/regions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchSubscriptionPlans } from "../../redux/actions/apiActions";
import { Check, Clear } from "@mui/icons-material";

const SubscriptionPlans = () => {

    const [billingType, setBillingType] = useState("monthly");

    const planList = useSelector(state => state.api.subscriptionPlans);
    const planListLoading = useSelector(state => state.api.SubscriptionPlansLoading);
    const dispatch = useDispatch();

    const onClickRegister = () => {
        let onboardingUrl = Config.onboardingUrl + '/register';
        window.open(onboardingUrl);
    }

    const pricingPlans = [
        {
            name: "Free",
            image: freePlanImage,
            pricePerMonth: 0,
            pricePerYear: 0,
            currency: "INR",
            buttonLabel: "Sign up now !!",
            buttonAction: onClickRegister,
            features:
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 5 }}>
                    <dt>Upto 5 Services 🗸 </dt><hr />
                    <dt>Upto 5 Providers 🗸 </dt><hr />
                    <dt>Reports for 30 days trial 🗸</dt><hr />
                    <dt>Mobile App 🗸</dt><hr />
                    <dt><s>Support for Products </s></dt><hr />
                    <dt><s>Virtual Appointments </s></dt><hr />
                    <dt><s>Email Reminders </s></dt><hr />
                    <dt><s>SMS Notifications </s></dt><hr />
                    <dt><s>White Labeling </s></dt><hr />
                    <dt><s>Online Payments </s></dt><hr />
                    <dt><s>BlackListing (Prevent fraud) </s></dt><hr />
                    <dt><s>Activity Logs </s></dt><hr />
                    <dt><s>Whatsapp Bot </s></dt><hr />
                    <dt><s>Website integrations (CDN) </s></dt><hr />
                    <dt><s>Website integrations assistance </s></dt><hr />
                    <dt><s>Customizations on request </s></dt><hr />
                </div>
        },
        {
            name: "Growth",
            image: growthPlanImage,
            pricePerMonth: 1999,
            pricePerYear: 20000,
            currency: "INR",
            buttonLabel: "Sign up now !!",
            buttonAction: onClickRegister,
            features:
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 5 }}>
                    <dt>Upto 10 Services 🗸 </dt><hr />
                    <dt>Upto 10 Providers 🗸 </dt><hr />
                    <dt>Reports 🗸</dt><hr />
                    <dt>Mobile App 🗸</dt><hr />
                    <dt>Upto 15 Products 🗸</dt><hr />
                    <dt>Virtual Appointments 🗸</dt><hr />
                    <dt>Email Reminders 🗸</dt><hr />
                    <dt>SMS Notifications 🗸</dt><hr />
                    <dt>White Labeling 🗸</dt><hr />
                    <dt><s>Online Payments </s></dt><hr />
                    <dt><s>BlackListing (Prevent fraud) </s></dt><hr />
                    <dt><s>Activity Logs </s></dt><hr />
                    <dt><s>Whatsapp Bot </s></dt><hr />
                    <dt><s>Website integrations (CDN) </s></dt><hr />
                    <dt><s>Website integrations assistance </s></dt><hr />
                    <dt><s>Customizations on request </s></dt><hr />
                </div>
        },
        {
            name: "Professional",
            image: proPlanImage,
            pricePerMonth: 3999,
            pricePerYear: 40000,
            currency: "INR",
            buttonLabel: "Sign up now !!",
            buttonAction: onClickRegister,
            features:
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 5 }}>
                    <dt>Upto 20 Services 🗸 </dt><hr />
                    <dt>Upto 20 Providers 🗸 </dt><hr />
                    <dt>Reports 🗸</dt><hr />
                    <dt>Mobile App 🗸</dt><hr />
                    <dt>Upto 30 Products 🗸</dt><hr />
                    <dt>Virtual Appointments 🗸</dt><hr />
                    <dt>Email Reminders 🗸</dt><hr />
                    <dt>SMS Notifications 🗸</dt><hr />
                    <dt>White Labeling 🗸</dt><hr />
                    <dt>Online Payments 🗸</dt><hr />
                    <dt>BlackListing (Prevent fraud) 🗸</dt><hr />
                    <dt>Activity Logs 🗸</dt><hr />
                    <dt>Whatsapp Bot 🗸</dt><hr />
                    <dt>Website integrations (CDN) 🗸</dt><hr />
                    <dt><s>Website integrations assistance </s></dt><hr />
                    <dt><s>Customizations on request </s></dt><hr />
                </div>
        },
        {
            name: "Enterprise",
            image: enterprisePlanImage,
            pricePerMonth: null,
            pricePerYear: null,
            currency: "",
            buttonLabel: "Sign up now !!",
            buttonAction: onClickRegister,
            features:
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 5 }}>
                    <dt>Unlimited Services 🗸 </dt><hr />
                    <dt>Unlimited Providers 🗸 </dt><hr />
                    <dt>Reports 🗸</dt><hr />
                    <dt>Mobile App 🗸</dt><hr />
                    <dt>Unlimited Products 🗸</dt><hr />
                    <dt>Virtual Appointments 🗸</dt><hr />
                    <dt>Email Reminders 🗸</dt><hr />
                    <dt>SMS Notifications 🗸</dt><hr />
                    <dt>White Labeling 🗸</dt><hr />
                    <dt>Online Payments 🗸</dt><hr />
                    <dt>BlackListing (Prevent fraud) 🗸</dt><hr />
                    <dt>Activity Logs 🗸</dt><hr />
                    <dt>Whatsapp Bot 🗸</dt><hr />
                    <dt>Website integrations (CDN) 🗸</dt><hr />
                    <dt>Website integrations assistance 🗸</dt><hr />
                    <dt>Customizations on request 🗸</dt><hr />
                </div>
        },
    ]

    const featureKeyMap = {
        maxServices: ["Service Limit", "Number of services allowed to create"],
        maxProviders: ["Provider Limit", "Number of providers allowed to create"],
        maxProducts: ["Products", "Support for Product Listing and maximum allowed limits"],
        enableReports: ["Allows Reports", "Receive Daily/Weekly reports and option to generate & export reports"],
        virtualAppointments: ["Allows Virtual Appointments", "Provide services online through supported virtual platforms or video conference"],
        salesforceIntegration: ["Salesforce Support", "Includes Sales force details on reports"],
        reminderNotifications: ["Reminder Notifications", "Sends reminder notifications to customers for their bookings"],
        smsNotification: ["SMS Notifications", "Recieve important notifications over SMS"],
        whiteLabelling: ["White Labeling", "Removes Quezone brandings from emails and booking pages"],
        onlinePaymentType: ["Online Customer Payment", "Accept payment from customers throug supported payment gateways"],
        whatsAppBot: ["WhatsApp Bot Integration", "Allows customers to book for the services through WhatsApp"],
        blacklisting: ["BlackListing", "Allows providers to black list customers & Helps to prevent fraud bookings"],
        activityLog: ["Activity Logs", "View logs of all recent actions performed within the platform."],
        websiteIntegrationAssistance: ["Website Integration Assistance", "Provides assistance for integrating quezone related features on websites"],
        customFeatureImplementation: ["Customized Features", "Customizes or provides features as per requirements"],
    }

    const [plans, setPlans] = useState([]);
    const [features, setFeatures] = useState([]);
    const [hoveredCol, setHoveredCol] = useState(null);

    useEffect(() => {
        if (!Array.isArray(planList)) return; // Ensure planList is an array
        let planList_ = planList.filter(item => !item?.isCustom); // Filter default plans
        setPlans(planList_);
        if (!featureKeyMap || typeof featureKeyMap !== "object") return;
        const features_ = Object.entries(featureKeyMap).map(([key, value]) => ({
            name: value[0],
            description: value[1],
            plans: planList_.map(plan => ({
                planId: plan.id,
                isAvailable: plan[key],
                component: (typeof plan[key] === "number" && plan[key])
                    ? `${plan[key] === -1 ? "unlimited" : `up to ${plan[key]}`}`
                    : null,
            })),
        }));
        setFeatures(features_);
    }, [planList]);

    useEffect(() => {
        dispatch(fetchSubscriptionPlans());
    }, [])

    return (
        <>
            <GridContainer justifyContent="center" >
                <GridItem xs={10} md={10} lg={10} xl={8}>
                    {/*
                    <Typography sx={{ fontSize: 14, textAlign: 'center', color: 'primary.main' }}>Billed</Typography>
                    <GridContainer justifyContent="center" sx={{ zIndex: 999, }} >
                        <ToggleTabSwitch
                            onChange={(e, value) => { setBillingType(value) }}
                            value={billingType}
                            indicatorColor="secondary"
                            sx={{ minHeight: 30, bgcolor: "primary.main", p: '1px', borderRadius: 10, borderWidth: 1, borderColor: '#fff', zIndex: 51 }}
                            TabIndicatorProps={{ sx: { backgroundColor: '#fff' } }}
                            tabs={[
                                {
                                    label: "Monthly",
                                    value: "monthly",
                                    sx: { color: '#fff', textTransform: "none" }
                                },
                                {
                                    label: "Yearly",
                                    value: "yearly",
                                    sx: { color: '#fff', textTransform: "none" }
                                }
                            ]}
                        />
                    </GridContainer>
                    <GridContainer justifyContent="center" sx={{ zIndex: 50 }} >
                        {pricingPlans.map((item, index) =>
                            <GridItem xs={12} sm={12} md={3} lg={3} key={index}>
                                <Card square sx={{
                                    mx: "1px", mt: "-20px", mb: 5, textAlign: 'center',
                                    borderRadius: 0,
                                }}>
                                    <Box sx={{ mx: 'auto' }}>
                                        <img src={item.image} style={{ margin: "auto" }} alt="Plan-Image" />
                                    </Box>
                                    <Box sx={{ p: 2 }}>
                                        <Typography variant="overline" sx={{ fontSize: 18 }}>{item.name}</Typography>
                                        {item?.pricePerMonth !== null && item?.pricePerYear !== null ?
                                            <>
                                                <Box>
                                                    <Typography variant="overline" sx={{ fontSize: 14, px: 1 }}>
                                                        {item.currency}
                                                    </Typography>
                                                    <Typography variant="overline" sx={{ fontSize: 24, fontWeight: '600', color: "primary.main", }}>
                                                        {billingType == "monthly" ? item.pricePerMonth : item.pricePerYear}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                                                    {billingType == "monthly" ? " per month" : " per year"}
                                                </Typography>
                                            </>
                                            :
                                            <>
                                                <Box sx={{ mt: 14 }} />
                                            </>
                                        }
                                        <Box sx={{ mt: 3, textAlign: 'left', px: 2, mx: "auto" }}>
                                            {item?.features ?? ''}
                                        </Box>
                                        <Box sx={{ my: 2 }}>
                                            <Button onClick={item.buttonAction} variant='outlined'>
                                                {item.buttonLabel}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Card>
                            </GridItem>
                        )
                        }
                    </GridContainer>
                    */}
                    <TableContainer style={{ maxWidth: "100%", overflowX: "auto", padding: 10 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ backgroundColor: '#11111111' }}>Features</TableCell>
                                    {plans.map((plan, idx) => (
                                        <TableCell
                                            key={plan.id}
                                            align="center"
                                            style={{ fontWeight: "bold", minWidth: 150, verticalAlign: 'baseline', backgroundColor: '#11111111' }}
                                            onMouseEnter={() => setHoveredCol(idx)}
                                            onMouseLeave={() => setHoveredCol(null)}
                                        >
                                            <Typography>{plan.name}</Typography>
                                            <Typography style={{ marginTop: 15, fontWeight: '600' }}>
                                                {plan?.isCommissionBased ?
                                                    `${plan?.pricingData?.commission?.perBooking?.value ?? ''} ${plan?.pricingData?.commission?.perBooking?.currency ?? ''}`
                                                    : plan?.pricingData?.basePrice?.monthly ?
                                                        `${plan?.pricingData?.basePrice?.monthly?.value ?? ''} ${plan?.pricingData?.basePrice?.monthly?.currency ?? ''}`
                                                        : plan?.pricingData?.basePrice?.yearly ?
                                                            `${plan?.pricingData?.basePrice?.yearly?.value ?? ''} ${plan?.pricingData?.basePrice?.yearly?.currency ?? ''}`
                                                            : ""
                                                }
                                            </Typography>
                                            <Typography style={{ fontSize: 12 }}>
                                                {plan?.isCommissionBased ? 'per Booking'
                                                    : plan?.pricingData?.basePrice?.monthly ? 'per month'
                                                        : plan?.pricingData?.basePrice?.yearly ? "per year"
                                                            : ""
                                                }
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {features.map((feature) => (
                                    <TableRow key={feature.name}>
                                        <TableCell>
                                            <Typography>{feature?.name}</Typography>
                                            <Typography style={{ fontSize: 12, opacity: 0.6 }}>{feature?.description}</Typography>
                                        </TableCell>
                                        {feature?.plans?.map((item, index) => (
                                            <TableCell
                                                key={index}
                                                align="center"
                                                style={{ backgroundColor: (hoveredCol == index ? "#11111111" : "inherit") }}
                                                onMouseEnter={() => setHoveredCol(index)}
                                                onMouseLeave={() => setHoveredCol(null)}
                                            >
                                                {item?.component ?
                                                    item?.component
                                                    :
                                                    (item?.isAvailable ? <Check color="success" /> : <Clear color="error" />)
                                                }
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </GridItem>
            </GridContainer>
        </>
    )
}

export default SubscriptionPlans;

