export const defaultPlans = [
    {
        "id": "679cbc6a2877c6426f80121b",
        "name": "Free",
        "description": "",
        "maxProducts": 0,
        "maxServices": 5,
        "maxProviders": 5,
        "virtualAppointments": false,
        "salesforceIntegration": false,
        "onlinePaymentType": false,
        "smsNotification": false,
        "isCustom": false,
        "whatsAppBot": false,
        "websiteIntegrationAssistance": false,
        "whiteLabelling": false,
        "custwebCustomization": false,
        "reminderNotifications": false,
        "blacklisting": false,
        "activityLog": false,
        "customFeatureImplementation": false,
        "enableReports": false,
        "isCommissionBased": false,
        "pricingData": {
            "basePrice": {
                "monthly": {
                    "value": "0",
                    "currency": "INR",
                    "currencySymbol": "$"
                },
                "yearly": {
                    "value": "0",
                    "currency": "INR",
                    "currencySymbol": "$"
                }
            },
            "commission": null
        },
        "trialPeriod": 0,
        "createdDate": null,
        "lastModifiedDate": null
    },
    {
        "id": "679cbc6a2877c6426f80121c",
        "name": "Growth",
        "description": "",
        "maxProducts": 10,
        "maxServices": 10,
        "maxProviders": 10,
        "virtualAppointments": true,
        "salesforceIntegration": true,
        "onlinePaymentType": true,
        "smsNotification": true,
        "isCustom": false,
        "whatsAppBot": false,
        "websiteIntegrationAssistance": false,
        "whiteLabelling": true,
        "custwebCustomization": false,
        "reminderNotifications": true,
        "blacklisting": false,
        "activityLog": false,
        "customFeatureImplementation": false,
        "enableReports": true,
        "isCommissionBased": false,
        "pricingData": {
            "basePrice": {
                "monthly": {
                    "value": "500",
                    "currency": "INR",
                    "currencySymbol": "$"
                },
                "yearly": {
                    "value": "11000",
                    "currency": "INR",
                    "currencySymbol": "$"
                }
            },
            "commission": null
        },
        "trialPeriod": 0,
        "createdDate": null,
        "lastModifiedDate": null
    },
    {
        "id": "679cbc6a2877c6426f80121d",
        "name": "Professional",
        "description": "",
        "maxProducts": 30,
        "maxServices": 20,
        "maxProviders": 20,
        "virtualAppointments": true,
        "salesforceIntegration": true,
        "onlinePaymentType": true,
        "smsNotification": true,
        "isCustom": false,
        "whatsAppBot": true,
        "websiteIntegrationAssistance": false,
        "whiteLabelling": true,
        "custwebCustomization": false,
        "reminderNotifications": true,
        "blacklisting": true,
        "activityLog": true,
        "customFeatureImplementation": false,
        "enableReports": true,
        "isCommissionBased": false,
        "pricingData": {
            "basePrice": {
                "monthly": {
                    "value": "1000",
                    "currency": "INR",
                    "currencySymbol": "$"
                },
                "yearly": {
                    "value": "11000",
                    "currency": "INR",
                    "currencySymbol": "$"
                }
            },
            "commission": null
        },
        "trialPeriod": 0,
        "createdDate": null,
        "lastModifiedDate": null
    },
    {
        "id": "679cbc6a2877c6426f80121e",
        "name": "Pay-As-You-Go",
        "description": "",
        "maxProducts": 30,
        "maxServices": 20,
        "maxProviders": 20,
        "virtualAppointments": true,
        "salesforceIntegration": true,
        "onlinePaymentType": true,
        "smsNotification": true,
        "isCustom": false,
        "whatsAppBot": true,
        "websiteIntegrationAssistance": false,
        "whiteLabelling": true,
        "custwebCustomization": false,
        "reminderNotifications": true,
        "blacklisting": true,
        "activityLog": true,
        "customFeatureImplementation": false,
        "enableReports": true,
        "isCommissionBased": true,
        "pricingData": {
            "basePrice": {
                "monthly": null,
                "yearly": null
            },
            "commission": {
                "perBooking": {
                    "value": "5",
                    "currency": "INR",
                    "currencySymbol": ""
                }
            }
        },
        "trialPeriod": 0,
        "createdDate": null,
        "lastModifiedDate": null
    },
    {
        "id": "67a4b37d9b6dba2f6d81e1e9",
        "name": "Enterprise",
        "description": "",
        "maxProducts": -1,
        "maxServices": -1,
        "maxProviders": -1,
        "virtualAppointments": true,
        "salesforceIntegration": true,
        "onlinePaymentType": true,
        "smsNotification": true,
        "isCustom": false,
        "whatsAppBot": true,
        "websiteIntegrationAssistance": true,
        "whiteLabelling": true,
        "custwebCustomization": true,
        "reminderNotifications": true,
        "blacklisting": true,
        "activityLog": true,
        "customFeatureImplementation": true,
        "enableReports": true,
        "isCommissionBased": false,
        "pricingData": {
            "basePrice": {
                "monthly": null,
                "yearly": null
            },
            "commission": null
        },
        "trialPeriod": 0,
        "createdDate": 1738847101,
        "lastModifiedDate": 1738847101
    }
  ] 