import styled from "@emotion/styled";
import { ExpandMore, Favorite, MoreVert, Share } from "@mui/icons-material"
import { Box, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Chip, Collapse, IconButton, Typography } from "@mui/material"
import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom"
import { Config } from "../../config/regions";
import SkeletonCard from "../../components/Loaders/SkeletonCard";
import { defaultServiceImageUrl } from "../../config/constants";
import { formatPrice } from "../../utils/misc";

const ExpandMoreIcon = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    //marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const ProductServiceCard = ({ serviceData, isLoading }) => {

    const [expanded, setExpanded] = useState(false);
    const { orgRef } = useParams();
    const [expandEnabled, setExpandEnabled] = useState(true);
    const collapseContentRef = useRef(null)

    const handleHover = (expand) => {
        if (expandEnabled) {
            setExpanded(expand);
        }
    };

    useEffect(() => {
        if (collapseContentRef?.current) {
            if (collapseContentRef?.current?.clientHeight < 150) {
                setExpandEnabled(false);
            }
        }
    }, [collapseContentRef])

    const handleExpandClick = (e) => {
        e.preventDefault();
        setExpanded(!expanded);
    };
    const handleShareClick = async (e) => {
        e.preventDefault();
        if (navigator.share) {
            const shareDetails = { url: `${Config.domainUrl}/p/service/${orgRef}/${serviceData.id}`, text: serviceData.name };
            try {
                await navigator.share(shareDetails);
            } catch (error) {
                console.log(`Failed to Share`, error);
            }
        } else {
            console.log("Web share is currently not supported on this browser.");
        }
    };

    return (
        <>
            {isLoading ?
                <SkeletonCard cardType="service" />
                :
                <Card
                    sx={{ width: '100%', maxHeight: '100%', borderRadius: 5 }}
                    onMouseEnter={() => handleHover(true)}
                    onMouseLeave={() => handleHover(false)}
                >
                    <CardActionArea component={Link} to={`/p/service/${orgRef}/${serviceData?.id}`}
                        sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}
                    >
                        <CardMedia
                            component="img"
                            height="100"
                            image={serviceData?.image?.fileUrl ? serviceData?.image?.fileUrl : defaultServiceImageUrl}
                            alt="Service Image"
                            sx={{ height: 150, width: 150, objectFit: 'cover', borderRadius: 5 }}
                        />
                        <Box sx={{ alignSelf: 'stretch', position: 'relative', flex: 1, overflow: 'auto' }}>
                            <Collapse in={expanded} timeout="auto" collapsedSize="110px">
                                <Box sx={{ p: 2 }} ref={collapseContentRef} >
                                    <Typography variant="body2" sx={{ fontSize: 14, fontWeight: "600" }}>
                                        {serviceData?.name}
                                    </Typography>
                                    <Box sx={{ my: 2 }}>
                                        {serviceData?.tags && serviceData?.tags?.split(',').length ?
                                            serviceData?.tags?.split(',').map((tag, i) => i >= 0 && tag.trim() &&
                                                <Box sx={{ mb: 0.5 }} key={i}>
                                                    <Chip color={"info"} variant="outlined" label={tag} size="small" sx={{ fontSize: 10 }} onClick={() => { }} />
                                                </Box>
                                            )
                                            : null
                                        }
                                    </Box>
                                    <Typography variant="caption" sx={{ whiteSpace: 'pre-wrap', mb: 0 }} paragraph>
                                        {serviceData?.description}
                                    </Typography>
                                </Box>
                            </Collapse>
                            <Box sx={{ position: 'relative', right: 0, bottom: 0, zIndex: 200, p: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', alignSelf: 'flex-end', width: '100%', flexWrap: 'wrap' }}>
                                {serviceData?.price?.value ?
                                    <Typography variant="overline" sx={{ mr: 'auto', pr: 1, fontWeight: '600', whiteSpace: 'pre' }}>
                                        {formatPrice(serviceData?.price?.value ?? 0, serviceData?.price?.currency ?? '')}
                                    </Typography>
                                    : null
                                }
                                {expandEnabled ?
                                    <ExpandMoreIcon
                                        expand={expanded}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                    >
                                        <ExpandMore />
                                    </ExpandMoreIcon>
                                    : null
                                }
                                {navigator?.share ?
                                    <IconButton aria-label="share" onClick={handleShareClick} size="small">
                                        <Share />
                                    </IconButton>
                                    : null
                                }
                            </Box>
                        </Box>
                    </CardActionArea>
                </Card>
            }
        </>
    )
}
export default ProductServiceCard